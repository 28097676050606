import Api from './index'
const Axios = new Api().axios
const V1 = 'api/v1'

const SuratJalan = (data:any) => Axios.get(`${V1}/report/tracking-sj`, { params: data })
const ReturSuratJalan = (data:any) => Axios.get(`${V1}/report/tracking-rsj`, { params: data })
const ScanSuratJalanUserDivisi = (data:any) => Axios.get(`${V1}/report/scan-user-divisi`, { params: data })

export {
  SuratJalan, ReturSuratJalan, ScanSuratJalanUserDivisi
}
