





































import Vue from 'vue'
export default Vue.extend({
  props: [ 'items', 'loading' ],
  data() {
    return {
      menu: false,
      actions: [
        { id: 1, text: 'Cetak', icon: 'mdi-printer' },
        { id: 2, text: 'Export (CSV)', icon: 'mdi-table-arrow-right' },
        { id: 3, text: 'Export (PDF)', icon: 'mdi-file-pdf-box' },
      ]
    }
  },
  methods: {
    onAction(item:any) {
      this.$emit('emitOnAction', item)
      this.menu = false
    }
  }
})
